import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private translate: TranslateService,
  ) { }

  setInitialAppLanguage() {
    let language = this.translate.getBrowserLang();
    language = 'nl';
    this.translate.setDefaultLang(language);
  }

  setLanguage(lang) {
    this.translate.use(lang);
  }
}
