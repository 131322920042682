import { AppModule } from '../app.module';
import { NavController, Platform } from '@ionic/angular';
import { Router } from '@angular/router';

import { GlobalVar, GlobalService, LanguageService, ApiService, AuthService, StorageService, AlertService, TabsService, SettingsService } from 'src/app/services';

export class PageBase {
  router = AppModule.injector.get(Router);
  navCtrl = AppModule.injector.get(NavController);
  platform = AppModule.injector.get(Platform);

  globalvar = AppModule.injector.get(GlobalVar);
  globalService = AppModule.injector.get(GlobalService);
  tabsService = AppModule.injector.get(TabsService);
  languageService = AppModule.injector.get(LanguageService);
  apiService = AppModule.injector.get(ApiService);
  authService = AppModule.injector.get(AuthService);
  settingsService = AppModule.injector.get(SettingsService);
  storageService = AppModule.injector.get(StorageService);
  alertService = AppModule.injector.get(AlertService);

  constructor(){ }
}
