import { Component, Input, Output } from '@angular/core';

@Component({
  selector: 'ac-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label: string;
  @Input() type: string;
  @Input() ionClass: boolean;
  @Input() disabled: boolean;

}
