import { ModelBase } from "./base"

export class User extends ModelBase {
  name: string;
  firstname: string;
  middlename: string;
  lastname: string;
  email: string;
  email_verified_at: any;
  language: string;
  app_version: any;
  is_active: any;
  last_login: any;
  two_factor_confirmed_at: any;
  token: string;
  profile_photo: string;
  profile_photo_path: string;

  completed_onboarding: any;
  level: any;
  current_team_id: any;
  settings: UserSettings;
  [key: string]: any;
}

export class setUserSettings {
  user_id?: number;
  setting: string;
  value: any;
}
export class UserSettings {
  [key: string]: any
}

export class Credentials {
  email: string;
  password: string;

  constructor(email?: string, password?: string) {
    this.email = email;
    this.password = password;
  }
}

export class RegisterCredentials extends Credentials {
  firstname: string;
  lastname: string;
  password_confirmation: string;
  phonenumber: string;
  company: string;
  company_function: string;

  constructor(email?: string, password?: string, password_confirmation?: string, firstname?: string, lastname?: string, phonenumber?: string, company?: string, company_function?: string) {
    super();

    this.email = email;
    this.password = password;
    this.password_confirmation = password_confirmation;
    this.firstname = firstname;
    this.lastname = lastname;
    this.phonenumber = phonenumber;
    this.company = company;
    this.company_function = company_function;
  }
}
