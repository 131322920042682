import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { GlobalService, GlobalVar } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient,
    public globalvar: GlobalVar,
    public globalService: GlobalService
  ) { }

  async sanctum() {
    const request = this.http.get(`${this.globalvar._domain}/sanctum/csrf-cookie`);

    return this.req(request);
  }

  async get(endpoint: string, params?: any, reqOpts?: any, ) {
    if (!reqOpts) {
      reqOpts = {
        params: new HttpParams(),
        headers: new HttpHeaders(),
      };
    }

    // Support easy query params for GET requests
    if (params) {
      reqOpts.params = new HttpParams();
      for (let k in params) {
        reqOpts.params = reqOpts.params.set(k, params[k]);
      }
    }

    reqOpts = this.requestOptions(reqOpts);

    const request = this.http.get(this.initUrl(endpoint), reqOpts);

    return this.req(request);
  }

  async post(endpoint, body, reqOpts?: any) {
    if (!reqOpts) {
      reqOpts = {
        headers: new HttpHeaders(),
      };
    }

    reqOpts = this.requestOptions(reqOpts);

    const request = this.http.post(this.initUrl(endpoint), body, reqOpts);

    return this.req(request);
  }

  put(endpoint: string, body: any, reqOpts?: any) {
    const request = this.http.put(this.initUrl(endpoint), body, reqOpts);

    return this.req(request);
  }

  private req(request) {
    return new Promise((resolve, reject) => {
      request.subscribe((res: any) => {
        // console.log(res);
        resolve(res);
      }, (err) => {
        console.error(err);
        reject(err);
      });
    })
  }

  private requestOptions(reqOpts) {
    if (this.globalvar._token) {
      let bearer = `Bearer ${this.globalvar._token}`;
      reqOpts.headers = reqOpts.headers.set("Authorization", bearer);
    }

    // console.log(this.globalvar._defaultApiContentType, reqOpts['headers']['Content-Type']);

    if(this.globalvar._defaultApiContentType && !reqOpts['headers']['Content-Type']){
      reqOpts.headers = reqOpts.headers.set("Content-Type", this.globalvar._defaultApiContentType);
    }

    // console.log(reqOpts);

    return reqOpts;
  }

  private initUrl(route?: string): string {
    return `${this.globalvar._apiUrl}/${route}`;
  }
}
