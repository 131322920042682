import { Injectable } from '@angular/core';
import { GlobalVar, StorageService } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    public globalvar: GlobalVar,
    public storageService: StorageService,
  ) { }

  set(data) {
    this.storageService.set(this.globalvar._userToken, data.token);
    if (this.globalvar._token) {
      this.get();
    }
  }

  get() {
    this.storageService.get(this.globalvar._userToken).then((token) => {
      this.globalvar._token = token;
    });
  }

  put(data) {

  }

  remove() {

  }

  clear() {

	}
}
