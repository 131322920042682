import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

import { GlobalVar } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(
    public globalvar: GlobalVar
  ) { }

  async set(key: string, value: any) {
    await Preferences.set({
      key: this.globalvar._storageAppKey + '_' + key,
      value: JSON.stringify(value)
    });
  }

  async get(key: string) {
    const item = await Preferences.get({ key: this.globalvar._storageAppKey + '_' + key });
    return JSON.parse(item.value);
  }

  async remove(key: string) {
    await Preferences.remove({ key: this.globalvar._storageAppKey + '_' + key });
  }

  async clear() {
	  await Preferences.clear();
	}
}
