import { Component, Optional } from '@angular/core';
import { PageBase } from 'src/app/pages';

import { NavigationStart } from '@angular/router';
import { Device } from '@capacitor/device';
import { Subscription } from 'rxjs';

import { SplashScreen } from '@capacitor/splash-screen';
// import { LocalNotifications } from '@capacitor/local-notifications';
import { IonRouterOutlet } from '@ionic/angular';
import { App } from '@capacitor/app';
import { TranslateService } from '@ngx-translate/core';
import { StatusBar, Style } from '@capacitor/status-bar';

export let browserRefresh = false;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent extends PageBase {
  subscription: Subscription;
  public routerHidden: boolean = true;

  constructor(
    private translate: TranslateService,
    @Optional() private routerOutlet?: IonRouterOutlet
  ) {
    super();

    this.initializeApp();
  }

  ngOnInit(): void {
    this.subscribe();
    StatusBar.setOverlaysWebView({ overlay: true });

  }

  ionViewWillLeave() {
    this.subscribe();
  }

  subscribe() {
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        browserRefresh = !this.router.navigated;
      }
    });
  }

  initializeApp() {
    this.platform.ready().then(async () => {
      this.translate.setDefaultLang('nl');
      this.translate.use('nl');

      await SplashScreen.hide();
      this.authService.user('get');
      setTimeout(() => {
        this.routerHidden = false;
      }, 5500);

      this.languageService.setInitialAppLanguage();

      this.platform.backButton.subscribeWithPriority(10, () => {
        // console.warn('Handler was called!');
      });

      this.authService.authState.subscribe(state => {
        if (state || !this.globalvar._requiersLogin) {
          if (this.globalvar._appHasOnboarding) {
            this.authService.onboardingState.subscribe(state => {
              // TODO
              let returnTo = !this.globalvar._user.completed_onboarding ? '/tabs/profile' : '/tabs/home';
              state ? this.navCtrl.navigateRoot([returnTo]) : this.router.navigate(['/tabs/onboarding'], {replaceUrl: true});

            });
          } else {
            this.navCtrl.navigateRoot(['/tabs/home'], { replaceUrl: true });
          }

          // LocalNotifications.addListener('localNotificationActionPerformed', (payload) => {
          //   const route = payload.notification.extra.route;
          //   this.navCtrl.navigateRoot([route], { replaceUrl: true });
          // });
        } else {
          this.navCtrl.navigateRoot('/login', { replaceUrl: true });
        }
      });
    });
  }

  logDeviceInfo = async () => {
    const info = await Device.getInfo();

    console.log(info);
  };

  logBatteryInfo = async () => {
    const info = await Device.getBatteryInfo();

    console.log(info);
  };

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
