import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { NavController, Platform } from '@ionic/angular';

import { ApiService, GlobalVar, SettingsService, StorageService } from 'src/app/services';
import { TokenService } from './token.service';
import { Credentials, RegisterCredentials, User } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  authState = new BehaviorSubject(false);
  onboardingState = new BehaviorSubject(false);
  isLoggedIn = false;

  constructor(
    private navCtrl: NavController,
    private platform: Platform,
    public globalvar: GlobalVar,
    public apiService: ApiService,
    public tokenService: TokenService,
    public storageService: StorageService,
    public settingsService: SettingsService,
  ) {
    this.platform.ready().then(async () => {
      await this.user('get');
    });
  }

  login(credentials?: Credentials) {
    let body = {
      email: credentials.email,
      password: credentials.password,
      app_version: this.globalvar._version
    };
    return this.apiService.sanctum().then(response => {
      return this.apiService.post(this.globalvar._postLogin, body).then((data) => {
        if (data.hasOwnProperty("token")) {
          this.user('set', data);

          setTimeout(async () => {
            this.user('get');
          }, 500);
          return data;
        }
        return false
      });
    });
  }

  logout() {
    this.isUnauthenticated();
  }

  register(credentials?: RegisterCredentials) {
    let body = {
      email: credentials.email,
      password: credentials.password,
      password_confirmation: credentials.password_confirmation,
      firstname: credentials.firstname,
      lastname: credentials.lastname,
      phonenumber: credentials.phonenumber,
      company: credentials.company,
      company_function: credentials.company_function,
    };

    return this.apiService.post(this.globalvar._postRegister, body);
  }

  forgotpswd(email) {
    let body = {
      email: email,
    };

    return this.apiService.post(this.globalvar._postForgotpswd, body);
  }

  user(option, data?) {
    switch (option) {
      case 'set':
        this.storageService.set(this.globalvar._userKey, data.employee_info ?? data).then(() => {
          this.tokenService.set(data);
        }, (err) => {
          console.error('Fout bij het opslaan de user data.', err);
          this.isUnauthenticated();
        });
        break;
      case 'get':
        this.storageService.get(this.globalvar._userKey).then((user) => {
          if (user) {
            if (user.is_active == 0) {
              this.isUnauthenticated();
            } else {
              this.globalvar._user = user;

              this.tokenService.get();

              setTimeout(async () => {
                this.isLoggedIn = true;

                if (this.globalvar._appHasOnboarding) {
                  this.onboardingState.next(this.globalvar._user.last_login && this.globalvar._user.completed_onboarding ? true : false);
                }

                this.authState.next(true);
              }, 500);

            }
          } else {
            this.isUnauthenticated();
          }
        });
        break;
      case 'update':
        data.firstname = this.globalvar._user.firstname;
        data.lastname = this.globalvar._user.lastname;
        data.email = this.globalvar._user.email;
        data.phonenumber = this.globalvar._user.phonenumber;

        return this.apiService.post(`${this.globalvar._postUpdateUser}/${this.globalvar._user.id}`, data).then((data) => {
          this.user('set', data);
          this.tokenService.set(data);

          setTimeout(async () => {
            this.user('get');
          }, 500);
        }, (err) => {
          this.ifUnauthenticated(err);
        });
        break;
      case 'update-profile-image':
        return this.apiService.post(`${this.globalvar._putProfilePicture}/${this.globalvar._user.id}`, data).then((data: any) => {
          this.globalvar._user.profile_photo = data;
          this.user('set', this.globalvar._user);

          return data;
        }, (err) => {
          this.ifUnauthenticated(err);
        });
        break;
      case 'deactivate':
        return this.apiService.post(`${this.globalvar._postDeactivateUser}/${this.globalvar._user.id}`, data).then((data: any) => {
          this.logout();
          return data;
        }, (err) => {
          this.ifUnauthenticated(err);
        });
        break;
    }
  }

  isAuthenticated() {
    console.log('auth: '+this.globalvar._requiersLogin ? this.authState.value : true);
    return this.globalvar._requiersLogin ? this.authState.value : true;
  }

  isUnauthenticated() {
    this.storageService.remove(this.globalvar._userKey).then(() => {
      this.storageService.remove(this.globalvar._userToken);

      this.globalvar._user = null;
      this.globalvar._userSettings = {};
      this.globalvar._token = '';

      this.isLoggedIn = false;
      this.authState.next(false);
    });

  }

  ifUnauthenticated(err) {
    if (err.error?.hasOwnProperty("message") && err.error.message.startsWith("Unauthenticated")) {
      this.logout();
    }
  }
}
