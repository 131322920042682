import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ac-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true
    }
  ]
})
export class InputComponent {
  @Input() label: string;
  @Input() moduleName: string;
  @Output() moduleNameChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string;
  @Input() validation: boolean;
  @Input() placeholder: string;
  @Input() validationClass: string;
  @Input() type = 'text';
  @Input() icon: string;

  onChange: any = () => { };
  onTouched: any = () => { };
  disabled = false;
  validation_messages: any;

  focused: boolean;

  constructor() {

  }

  ngOnInit(): void {
    // console.log(this.validation);
    // const validation_messages = [
    //   {
    //     'email': [
    //       { type: 'required', message: '_EMAIL_REQUIRED' },
    //       { type: 'pattern', message: '_EMAIL_INVALID' }
    //     ]
    //   },
    //   {
    //     'password': [
    //       { type: 'required', message: '_PASSWORD_REQUIRED' },
    //       { type: 'pattern', message: '_PASSWORD_INVALID' }
    //     ]
    //   }
    // ];

    // this.validation_messages = Object.entries(validation_messages).find(([key, value]) => {
    //   let messages = value[this.name];
    //   return messages;
    // });
    // console.log(this.validation_messages[1][this.name]);
  }

  onBlur(e: any) {
    const value = e.target.value;

    if (!value) {
      this.focused = false;
    }
  }

  writeValue(value: string): void {
    this.moduleName = value;
    this.moduleNameChange.emit(this.moduleName);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setValue(e) {
    this.moduleName = e.target.value;
    this.onChange(this.moduleName);
    // console.log(this.moduleName);
    this.moduleNameChange.emit(this.moduleName);
  }
}
