import { Component, ContentChild } from '@angular/core';
import { InputComponent } from 'src/app/components/form-field/input/input.component';

@Component({
  selector: 'ac-password',
  templateUrl: './show-hide-password.component.html',
  styleUrls: ['./show-hide-password.component.scss']
})
export class ShowHidePasswordComponent {
  showPassword = false;

  @ContentChild(InputComponent) input: InputComponent;

  constructor() {}

  toggleShow() {
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }
}
