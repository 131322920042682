import { Component, ContentChild, EventEmitter, forwardRef, Input, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ac-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaComponent),
      multi: true
    }
  ]
})
export class TextareaComponent {
  @Input() label: string;
  @Input() moduleName: string;
  @Output() moduleNameChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() name: string;
  @Input() validation: boolean;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() row: string;
  @Input() validationClass: string;

  onChange: any = () => { };
  onTouched: any = () => { };
  disabled = false;
  validation_messages: any;

  focused: boolean;

  constructor() {

  }

  ngOnInit() { }

  onBlur(e: any) {
    const value = e.target.value;

    if (!value) {
      this.focused = false;
    }
  }

  writeValue(value: string): void {
    this.moduleName = value;
    this.moduleNameChange.emit(this.moduleName);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  setValue(e) {
    this.moduleName = e.target.value;
    this.onChange(this.moduleName);
    this.moduleNameChange.emit(this.moduleName);
  }
}
