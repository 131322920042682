import { Injectable } from '@angular/core';
import { ApiService, AuthService, GlobalVar } from 'src/app/services';

@Injectable({
  providedIn: 'root'
})
export class OnboardingService {

  constructor(
    public authService: AuthService,
    public globalvar: GlobalVar,
    public apiService: ApiService,
  ) { }

  post(body) {

  }

  put(status: number) {
    let body = {
      completed_onboarding: status
    }

    this.authService.user('update', body);
  }

  get() {
    return this.apiService.get(this.globalvar._getOnboarding);
  }
}
