import { Injectable } from "@angular/core";
import { CanActivate } from "@angular/router";
import { AuthService } from 'src/app/services';

@Injectable({
  providedIn: "root"
})
export class AuthGuardService implements CanActivate {
  constructor(
    public authService: AuthService
  ) { }

  canActivate(): boolean {
    return this.authService.isAuthenticated();
  }
}
