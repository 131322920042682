// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const app_name = "Uitslagen.nl";
const app_id = app_name.replace(/\s+/g, '').toLowerCase();
const app_domain = "https://uitslagen.nl";
const app_author = "AppComm";
const app_author_url = "https://appcomm.nl";

export const environment = {
  production: false,
  APP_NAME: `${app_name}`,
  APP_DESCRIPTION: `${app_name} made by ${app_author}`,
  APP_ID: `nl.appcomm.${app_id}`,
  APP_VERSION: `1.5.8`,
  APP_DOMAIN: `${app_domain}`,
  APP_IMAGE_URL: `${app_domain}`,
  APP_NO_IMAGE_URL: `model_images/no-image.png`,
  APP_ROOT_API: `${app_domain}/api/app`,
  APP_API_CONTENT_TYPE: `application/x-www-form-urlencoded`,

  APP_TOOLBAR_APP_NAME: false,
  APP_LOGIN_REQUIERD: false,
  APP_ONBOARDING: false,

  APP_AUTHOR: `${app_author}`,
  APP_AUTHOR_URL: `${app_author_url}`,

  GOOGLEMAPS_APIKEY: `AIzaSyBUqnG4EQokSEVgInRECn0Os7FbCP7D6Wc`,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
