import { Pipe, PipeTransform } from '@angular/core';
import { Chapter } from 'src/app/models/chapters';

@Pipe({
  name: 'chapterType',
})
export class ChapterStatusPipe implements PipeTransform {
  /**
   * filters chapters by status.
   */
  transform(value: Chapter[], status: number) {
    if (value) {
      return value.filter(x => x.system_type == status);
    }
  }
}
