import { Component, Input, OnInit, Output, EventEmitter, ViewChild, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { IonInfiniteScroll, IonContent } from '@ionic/angular';

@Component({
  selector: 'ac-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  viewProviders: [IonContent]
})
export class TableComponent implements OnInit, OnChanges {
  @ViewChild('infiniteScroll', {read: IonInfiniteScroll}) infiniteScroll: IonInfiniteScroll;

  @Input() name: string                           = "";
  @Input() headerList: Array<string|string[]>     = [];
  @Input() keyList: Array<string|string[]>        = [];
  @Input() colSize: Array<number>                 = [];
  @Input() content: Array<any>|object             = [];
  @Input() valueAlteration: any = []; //Array<undefined | Array<((data) => any)> | ((data) => any)>
  @Input() footerList: Array<string|string[]>     = [];
  @Input() type: string                           = 'array';
  @Input() searching: boolean                     = false;
  @Input() limit: number|undefined                = 20;
  @Output() itemClick: EventEmitter<any>          = new EventEmitter<any>();

  public disabled: boolean = false;

  defaultColSize: number;

  constructor() {
  }

  ngOnInit() {
    this.defaultColSize =  this.type === 'array' ? Math.ceil(12/this.keyList.length) : 6;
  }

  typeOf(objectKey: string, equals: boolean, type: string): boolean
  {
      const result = equals ? typeof objectKey === type : typeof objectKey !== type;
      return result;
  }

  click(event: any, data: any) {
    this.itemClick.emit(data);
  }

  getKeys(object: any) {
    if(this.keyList.length > 0){
      return this.keyList;
    }

    return Object.keys(object);

  }

  determineActualHeader(i, value) {
    return typeof this.headerList[i] === 'undefined' ? value : this.headerList[i];
  }

  determineActualFooter(i, value) {
    return typeof this.footerList[i] !== 'undefined' ? this.footerList[i] : (typeof this.headerList[i] === 'undefined' ? value : this.headerList[i]);
  }

  // determineActualHeader(i, value) {
  //   return typeof this.headerList[i] === 'undefined' ? value : this.headerList[i];
  // }


  determineActualValue(i, value, x = null) {
    if(typeof this.valueAlteration[i] === 'undefined'){
      return value;
    }
    else if(x !== null){
      return typeof this.valueAlteration[i][x] === 'undefined' ? value : this.valueAlteration[i][x](value);
    }

    return this.valueAlteration[i](value);

  }

  determineActualValueObject(i, object, value) {
    if(typeof object !== 'undefined' && typeof object[value] !== 'undefined'){
      return typeof this.valueAlteration[i] === 'undefined' ? object[value] : this.valueAlteration[i](object[value]);
    }
    return '';
  }

  loadData(event) {
    if(Array.isArray(this.content)){
      console.log(this.limit, this.content.length);
    }
    setTimeout(() => {
      if(this.limit !== undefined) {
        this.limit += 10;
      }

      event.target.complete();



      if ( Array.isArray(this.content) && this.limit >= this.content.length || this.limit === undefined ) {
        event.target.disabled = true;
        this.disabled = true;
      }
      // console.log(event.target, this.limit, event.target.disabled);

    }, 300);
  }


  ngOnChanges(changes: SimpleChanges) {
    this.disabled = false;
    console.log('changes');
  }

}
