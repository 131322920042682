import { Injectable } from '@angular/core';
import { GlobalVar } from './globalvar';

@Injectable({
  providedIn: 'root'
})
export class GlobalService extends GlobalVar {

  constructor() {
    super();
  }

  getFullName(key): string
  {
    let names = {
			'FR' : 'Friesland',
			'GR' : 'Groningen',
			'DR' : 'Drenthe',
			'OV' : 'Overijssel',
			'GE' : 'Gelderland',
			'UT' : 'Utrecht',
			'FL' : 'Flevoland',
			'NH' : 'Noord-Holland',
			'ZH' : 'Zuid-Holland',
			'ZE' : 'Zeeland',
			'NB' : 'Noord-Brabant',
			'LI' : 'Limburg',

			'NED': 'Nederland',
			'BEL': 'België',
			'LUX': 'Luxemburg',
			'GER': 'Duitsland',
			'ESP': 'Spanje',
			'POL': 'Polen'
		};
    return names[key] ?? key;
  }

}
