import { Pipe, PipeTransform } from '@angular/core';
import { CheckinExtended } from 'src/app/models/checkin';

@Pipe({
  name: 'checkinFilter',
})
export class CheckinFilterPipe implements PipeTransform {
  /**
   * filters chapters by status.
   */
  transform(value: CheckinExtended[], status: any) {
    if (value) {
      let checkStatus = status.match(/\d+/g) ?? false;

      if (checkStatus) {
        return value.filter(x => x.chapter?.system_type == status.split('-').pop());
      } else if (status == 'mood') {
        return value.sort((n1, n2) => {
          if (n1.mood > n2.mood) {
            return 1;
          }

          if (n1.mood < n2.mood) {
            return -1;
          }
          return 0
        });
      }
    }
    return value.sort((n1, n2) => {
      if (n1.id > n2.id) {
        return 1;
      }

      if (n1.id < n2.id) {
        return -1;
      }
      return 0
    });
  }
}
