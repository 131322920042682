import { Injectable } from '@angular/core';
import { User, UserSettings } from 'src/app/models/user';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GlobalVar {

  // App environment variables
  public _version     = environment.APP_VERSION;
  public _name        = environment.APP_NAME;
  public _description = environment.APP_DESCRIPTION;
  public _domain      = environment.APP_DOMAIN;
  public _apiUrl      = environment.APP_ROOT_API;
  public _defaultApiContentType = environment.APP_API_CONTENT_TYPE;
  public _imgUrl      = environment.APP_IMAGE_URL;
  public _noImgUrl    = environment.APP_NO_IMAGE_URL;
  public _toolbarName = environment.APP_TOOLBAR_APP_NAME;

  public _author      = environment.APP_AUTHOR;
  public _authorUrl   = environment.APP_AUTHOR_URL;

  public _requiersLogin = environment.APP_LOGIN_REQUIERD;
  public _appHasOnboarding = environment.APP_ONBOARDING;

  public _storageAppKey = `${this._name}`;
  public _userKey       = 'USER';
  public _userToken     = 'TOKEN';

  // User data
  public _user: User;
  public _userSettings: UserSettings = new UserSettings();
  public _token;

  public _device;

  // API endpoints
  public _postLogin          = 'login';
  public _postRegister       = 'register';
  public _postForgotpswd     = 'request-password';
  public _postUpdateUser     = 'update-user';
  public _postUserSetting    = 'user-setting';
  public _getUserSettings    = 'user-settings';
  public _putProfilePicture  = 'update-profile-photo';
  public _postDeactivateUser = 'deletion-request-user';
  public _getOnboarding      = 'get-onboarding';
  public _postFeedback       = 'create-feedback';
  public _getContact         = 'get-contact';
  public _getContacts        = 'get-contact';
  public _createChapter      = 'create-chapter';
  public _putChapter         = 'update-chapter';
  public _getChapters        = 'get-chapters';
  public _createCheckin      = 'create-checkin';
  public _putCheckin         = 'update-checkin';
  public _getCheckins        = 'get-checkins';
  public _getProtocols       = 'get-introduction';
  public _getAssignments     = 'get-assignments';
  public _putAssignment      = 'complete-assignment';

  public _postResultComplete = 'get_completelijst.php';
  public _postResults        = 'search_uitslagen.php';
  public _postResultDetails  = 'get_uitslagdetails.php';
  public _postEvents         = 'search_evenementen.php';
  public _postEventDetails   = 'get_evenementdetail.php';
  public _getPersonDetails   = 'get_persondetails.php';
  public _postSignup         = 'search_inschrijven.php';
  public _getNews            = 'get_nieuws.php';
  public _postArchive         = 'search_history.php';

  // Form verification patterns
  public _verificationEmail    = '[a-zA-Z0-9._]+[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}';
  public _verificationPassword = '^.{3,}$';
  // public _verificationPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$'; // This is for the letters (both uppercase and lowercase) and numbers validation
  public _verificationName     = '[a-zA-Z ]*';
  public _verificationPhone    = '^((\\+91-?)|0)?[0-9]{10}$';
  public _verificationStreet   = '[a-zA-Z0-9 \,\.\-]*';
  public _verificationZipcode  = '^[a-z0-9][a-z0-9\- ]{0,10}[a-z0-9]$';
  public _verificationCity     = '[a-zA-Z \,\.\-]*';
  public _verificationCountry  = '[a-zA-Z \,\.\-]*';
  public _verificationTextarea = '^.{0,}$';
  public _verificationNumber   = '^-?[0-9]*[.]?[0-9]+$';

  public _googleMapsApiKey     = environment.GOOGLEMAPS_APIKEY ? environment.GOOGLEMAPS_APIKEY : '';


  constructor() { }
}
