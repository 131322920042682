import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateModule } from '@ngx-translate/core';

import { HeaderComponent } from './header/header.component';

import { ButtonComponent } from './button/button.component';

import { InputComponent } from './form-field/input/input.component';
import { TextareaComponent } from './form-field/textarea/textarea.component';
import { ShowHidePasswordComponent } from './form-field/show-hide-password/show-hide-password.component';
import { TableComponent } from './table/table.component';

@NgModule({
	declarations: [
    HeaderComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    ShowHidePasswordComponent,
    TableComponent,
  ],
	imports: [
	  CommonModule,
		FormsModule,
    ReactiveFormsModule
	],
	exports:[
		TranslateModule,
    HeaderComponent,
    ButtonComponent,
    InputComponent,
    TextareaComponent,
    ShowHidePasswordComponent,
    TableComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

export class ComponentsModule {}
