import { NgModule } from '@angular/core';
import { ChapterStatusPipe } from './chapters';
import { SafeHtmlPipe } from './safehtml';
import { CheckinFilterPipe } from './checkins';
import { DatePipe } from '@angular/common';

@NgModule({
	declarations: [
    ChapterStatusPipe,
    SafeHtmlPipe,
    CheckinFilterPipe,
  ],
	imports: [],
	exports: [
    ChapterStatusPipe,
    SafeHtmlPipe,
    CheckinFilterPipe,
  ],
  providers: [
    DatePipe
  ]
})
export class PipesModule {}
