import { Injectable } from '@angular/core';

import { ApiService, GlobalVar, StorageService } from 'src/app/services';
import { setUserSettings, UserSettings } from 'src/app/models/user';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    public globalvar: GlobalVar,
    public apiService: ApiService,
    public storageService: StorageService,
  ) { }

  user(option, body?: setUserSettings) {
    switch (option) {
      case 'set':
        body.user_id = this.globalvar._user.id;

        this.apiService.post(this.globalvar._postUserSetting, body);
        break;

      case 'get':
        return this.apiService.get(`${this.globalvar._getUserSettings}/${this.globalvar._user.id}`).then((data: any) => {
          data.forEach((x: setUserSettings) => {
            if (!/\s/.test(x.setting)) {
              this.globalvar._userSettings[x.setting] = x.value;
            }
          });
        }, (err) => {
          if (err.status == 404) {
            return err.error;
          }
        });
        break;

      case 'put':
        break;
    }
  }
}
