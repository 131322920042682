import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavController } from '@ionic/angular';
import { AuthService, GlobalVar } from 'src/app/services';
@Component({
  selector: 'ac-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  @Input() title: string;
  @Input() notification: string;
  @Input() profile: boolean;
  @Input() logoutBtn: boolean;
  @Input() enableBackBtn: boolean;
  @Input() backBtnRoute: string = '';
  @Input() background: string;
  @Output() searchValue: EventEmitter<any> = new EventEmitter<any>();

  public searchIsUsed: boolean = false;

  constructor(
    private navCtrl: NavController,
    private route: ActivatedRoute,
    public globalvar: GlobalVar,
    public authService: AuthService,
  ) { }

  ngOnInit() {
    this.searchIsUsed = this.searchValue.observers.length > 0;
  }

  goBack() {
    if (this.backBtnRoute) {
      this.navCtrl.navigateRoot([this.backBtnRoute], { relativeTo: this.route });
    } else {
        this.navCtrl.back();
    }
  }

  goProfile() {
    this.navCtrl.navigateForward('/tabs/profile');
  }

  logout() {
    this.authService.logout();
  }

  search(event){
    this.searchValue.emit(event.detail.value);
  }
}
